import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../components/common/Header';
import Footer from '../components/home/MainFooter';
import SubHeader from '../components/common/SubHeader';

import News from '../components/news/News';
import Blog from '../components/news/Blog';

const NewsBlog = () => (
    <>
        <Helmet>
            <title>Comisión de Energía del CCE | Noticias</title>
            <meta name="description" content="Entérate de las noticias que giran en torno al sector energético y los comunicados que la Comisión de Energía del CCE anuncia" />
        </Helmet>
        <main style={{ overflow: 'auto ' }}>
            <Header section="news" />
            <SubHeader title="Noticias" />
            <News />
            <Blog />
            <Footer />
        </main>
    </>
);

export default NewsBlog;
