import React from 'react';
import Microlink from '@microlink/react';
import { useMediaQuery } from 'react-responsive';
import { useStaticQuery, graphql } from 'gatsby';

import styles from './styles';

const NewsCmp = () => {
    const isMinWidth = useMediaQuery({ query: '(min-width: 1024px)' });
    const datum = useStaticQuery(graphql`
        query NewsQuery {
          allStrapiNoticias {
            edges {
              node {
                source
                url
              }
            }
          }
        }
    `);
    const { edges } = datum.allStrapiNoticias;
    const filtEdges = edges.filter((edge) => edge.node.source !== null);
    return (
        <section css={styles} className="news">
            <Microlink url={filtEdges[0].node.url} size={isMinWidth ? 'large' : 'normal'} />
            <div>
                {filtEdges.map((edge, i) => {
                    if (i > 0) {
                        return (
                            <Microlink url={edge.node.url} />
                        );
                    }
                    return null;
                })}
            </div>
        </section>
    );
};

export default NewsCmp;
