import { css } from '@emotion/core';

export default css({
    width: '100%',
    padding: '50px 0px',
    background: 'rgb(249, 249, 249)',
    '& > h3': {
        fontSize: 28,
        color: '#959595',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: 50
    },
    '& .blog-posts': {
        maxWidth: '1000px',
        margin: '0px auto',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridGap: '1em',
    },
    '& .blog-post': {
        width: 300,
        height: 190,
        margin: 15,
        padding: 20,
        background: '#FFF',
        boxSizing: 'border-box',
        boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
        position: 'relative',
        justifySelf: 'center',
        '& > a': {
            transition: 'box-shadow 0.4s ease-in-out',
            textDecoration: 'none',
            color: '#1f300d',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            '&:focus': {
                outline: 0
            }
        },
        '& h4': {
            fontSize: 20,
            textAlign: 'center',
            textTransform: 'uppercase',
            margin: '10px 0px 20px',
            lineHeight: '26px'
        },
        '& h6': {
            position: 'absolute',
            bottom: '16px',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    },
    '& .pagination': {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 1000,
        margin: '30px auto 0px',
        '& li': {
            width: 40,
            height: 48,
            marginLeft: '-1px',
            background: '#FFF',
            border: '1px solid #ddd',
            '& > a': {
                width: '100%',
                color: '#1f300d',
                textDecoration: 'none',
                textAlign: 'center',
                display: 'block',
                padding: '16px 0px',
                boxSizing: 'border-box'
            },
            '&.disabled': {
                '& > a': {
                    color: '#ddd',
                }
            },
            '&.active': {
                '& > a': {
                    fontSize: 18,
                    fontWeight: 'bold'
                }
            }
        }
    },
    '@media only screen and (min-width: 1024px)': {
        '& .pagination': {
            justifyContent: 'flex-end',
        }
    }
});
