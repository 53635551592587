import React from 'react';
import { css } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';

import { Link } from '../../common/Misc';
import styles from './styles';

const linkStyles = css`
  box-shadow: none;
`;

const blogQuery = graphql`
  query BlogQuery {
    content: allStrapiGrupoComunicados(sort: { order: ASC, fields: name }) {
      edges {
        node {
            id
            name
            slug
        }
      }
    }
  }
`;

const Blog = () => {
    const { content } = useStaticQuery(blogQuery);
    const { edges } = content;
    return (
        <section css={styles} className="blog">
            <h3>Comunicados</h3>
            <div className="blog-posts">
                {edges
                    .map(({ node: post }) => ((
                        <div
                            key={post.id}
                            className="blog-post"
                        >
                            <Link
                                to={`/comunicados${post.slug}`}
                                className={linkStyles}
                                rel="nofollow noopener noreferrer"
                            >
                                <h4>{post.name}</h4>
                                <h6>Abrir</h6>
                            </Link>
                        </div>
                    )))}
            </div>
        </section>
    );
};

export default Blog;
