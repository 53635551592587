import { css } from '@emotion/core';

export default css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '80px auto',
    '& > a': {
        marginBottom: 10
    },
    '& > div > a': {
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0
        }
    },
    '@media only screen and (max-width: 1023px)': {
        '& a': {
            width: '90%'
        },
        '& > div': {
            width: '100%',
            '& > a': {
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        }
    },
    '@media only screen and (min-width: 1024px)': {
        flexDirection: 'row',
        '& > a': {
            height: 400,
            marginRight: 20,
            marginBottom: 0
        },  
    }
});
